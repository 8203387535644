import * as React from "react";
import "./FeatureCard.scss";

const FeatureCard = ({ feature }) => {
  return (
    <div className="feature-card">
      <p className="feature-title">{feature?.title}</p>
      <p className="description">{feature?.description}</p>
      <p className="release">Release: {feature?.release}</p>
    </div>
  );
};

export default FeatureCard;
